/* AboutUs.css */

.about-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px; /* Ajusta el espacio interno según tus necesidades */
}

/* Agregamos una regla de medios para dispositivos más pequeños */
@media (max-width: 768px) {
    .about-us-container {
        padding: 10px; /* Reducimos el espacio interno en dispositivos más pequeños */
    }

}
