/* Slider.css */
.slider-container {
    width: 100%; /* Puedes ajustar este valor según tus necesidades */
}

.custom-slider {
    width: 100%;
}

.slider-image {
    width: 100%;
    height: auto;
    object-fit: contain; /* Esto mantendrá la relación de aspecto de la imagen y evitará que se corte */
}
