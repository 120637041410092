/* ContactUs.css */

/* Estilos generales */
.contact-us-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-container {
    display: flex;
    justify-content: center;
    flex-direction: row; /* Cambiado a columna para móviles */
    gap: 44px; /* Espacio entre las secciones */
}

.left-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.right-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-us-paper {
    padding: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-us-paper h2 {
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.button-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

/* Consulta de medios para dispositivos con un ancho máximo de 600px (móviles) */
@media (max-width: 900px) {
    .flex-container {
        flex-direction: column;
        gap: 10px; /* Espacio reducido para móviles */
    }

    .left-section {
        justify-content: space-evenly;
        flex-direction: column;
        gap: 10px;
        order: 2; /* Cambia el orden de la sección izquierda en móviles */
    }

    .right-section {
        order: 1; /* Cambia el orden de la sección derecha en móviles */
    }
}
