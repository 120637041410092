.partners-container {
    padding: 40px;
}

.partner-card {
    padding: 20px;
    text-align: center;
}

.partner-logo {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}
