@import url('https://fonts.googleapis.com/css2?family=NuevaFuente&display=swap');

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'NuevaFuente', sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: #000000;
  display: flex;
  align-items: center;
}

.App-logo {
  height: 40px;
  margin-right: 10px;
}

.App-nav {
  background-color: #333;
  padding: 10px;
}

.App-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.App-nav li {
  margin: 0 10px;
}

.App-nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.App-main {
  padding: 0;
}

.App-main section {
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden; /* Agrega overflow: hidden para ocultar las imágenes que se desborden */
}

.full-width {
  width: 100%;
  height: 100vh;
}

.App-footer {
  background-color: #282c34;
  color: white;
  padding: 10px;
  bottom: 0;
  width: 100%;
}



