/* Presentation.css */
.presentation-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.presentation-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-bottom: 20px;
}

.presentation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}
