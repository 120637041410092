/* Header.css */
.header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7); /* Establecido inicialmente a transparente */
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    transition: background-color 0.3s ease; /* Transición para suavizar el cambio de color de fondo */
}

.header-container.scrolled {
    background-color: rgba(0, 0, 0, 0.3); /* Cambiado a 0.7 cuando se hace scroll para mayor visibilidad */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Añadido un poco de sombra cuando se hace scroll */
}

.header-logo {
    height: 80px;
}

.header-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.header-nav li {
    margin: 0 15px;
}

.header-nav a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 16px;
}

