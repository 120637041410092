/* products.css */

.products-container {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;
    padding: 3px;
}

.product-card {
    max-width: 300px;
    width: 100%;
    margin-right: 10px;
}

/* Agregamos una regla de medios para dispositivos más pequeños */
@media (max-width: 768px) {
    .products-container {
        flex-direction: column; /* Cambia a una disposición de columna en dispositivos más pequeños */
        align-items: center; /* Centra los elementos en dispositivos más pequeños */
    }

    .product-card {
        margin-right: 0;
        margin-bottom: 10px; /* Agregamos espacio entre las tarjetas en dispositivos más pequeños */
    }
}
