/* OurWork.css */

.our-work-card {
    display: flex;
    flex-direction: column; /* Cambiado de row a column */
    align-items: center;
    margin: 20px;
}

#fullscreen-media {
    height: auto; /* Utiliza la altura automática para mantener la proporción original */
    width: 100%;  /* Utiliza el ancho completo del contenedor */
    object-fit: cover;
}

.our-work-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    width: 100%; /* Utiliza el ancho completo del contenedor */
}

.our-work-actions {
    display: flex;
    align-items: center;
}

.our-work-actions .MuiIconButton-root {
    transition: color 0.3s ease-in-out; /* Agrega una transición de color suave */
}

.our-work-actions .MuiIconButton-root:hover {
    color: red; /* Cambia el color cuando el mouse está sobre el botón */
}

/* Agrega media queries para adaptar el diseño a diferentes tamaños de pantalla */
@media (min-width: 600px) {
    .our-work-card {
        flex-direction: row;
    }

    #fullscreen-media {
        width: 50%; /* Reduzca el ancho en pantallas más grandes */
    }

    .our-work-card-content {
        width: 50%;
    }
}

@media (min-width: 900px) {
    .our-work-card {
        flex-direction: row;
    }

    #fullscreen-media {
        width: 40%; /* Ajusta el ancho según las necesidades en pantallas más grandes */
    }

    .our-work-card-content {
        width: 60%;
    }
}

/* Agrega más media queries según sea necesario */
